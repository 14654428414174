import PopupCloseIconButtonComponent from '../components/PopupCloseIconButton/viewer/PopupCloseIconButton';


const PopupCloseIconButton = {
  component: PopupCloseIconButtonComponent
};


export const components = {
  ['PopupCloseIconButton']: PopupCloseIconButton
};

